import {
  ComponentClientSpecMapEntry,
  EventType,
} from '@wix/editor-platform-sdk-types';
import { installMembersArea } from '@wix/members-area-integration-kit';
import type { EditorReadyFn } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

import { GROUPS_APP_DEFINITION_ID } from '../../constants';
import { EManagementAction } from '../constants';
import { isSilentInstallation } from './helpers';
import { MigrationAssistant } from './MigrationAssistant';

export const editorReady: EditorReadyFn = async (
  sdk,
  appDefinitionId,
  options,
  flowAPI,
) => {
  const { firstInstall, initialAppData, origin } = options;
  const { applicationId } = initialAppData;
  const {
    errorMonitor,
    translations: { t },
  } = flowAPI;

  const migrator = new MigrationAssistant(applicationId, sdk, flowAPI);

  try {
    if (firstInstall && !isSilentInstallation(origin.info?.type)) {
      await installMembersArea({ biData: options.biData });
    }

    if (firstInstall) {
      await renamePages();
      await migrator.install();
    }

    await configureGroupPage();
    await migrator.migrate();
  } catch (err) {
    errorMonitor.captureException(err as Error, {
      contexts: { file: { name: 'editor-ready/index.ts' } },
    });
    console.error(err);
  }

  sdk.addEventListener('openDashboard' as EventType, function () {
    openDashboard();
  });

  sdk.addEventListener('uninstall' as EventType, function () {
    sdk.application.uninstall(GROUPS_APP_DEFINITION_ID, {
      openConfirmation: true,
    });
  });

  sdk.addEventListener('widgetAdded', function (event) {
    return migrator.installComponent({
      id: event.detail.componentRef.id,
      widgetId: event.detail.widgetId,
    } as ComponentClientSpecMapEntry);
  });

  sdk.addEventListener('appActionClicked', function (data) {
    switch (data.detail.actionId) {
      case EManagementAction.MainAction:
        return openDashboard();

      case EManagementAction.CreateGroup:
        return openDashboard('templates');

      case EManagementAction.Setup:
        return openDashboard('settings');

      case EManagementAction.Addons:
        return sdk.editor.openModalPanel('', {
          width: 800,
          height: 165 * 3,
          url: getPanelUrl('Groups', 'Addons'),
          title: t('groups-web.settings.addons'),
        });

      default:
        return;
    }
  });

  function openDashboard(section?: 'settings' | 'templates') {
    const url = !section
      ? '/social-groups-dashboard'
      : `/social-groups-dashboard/${section}`;

    return sdk.editor.openDashboardPanel(GROUPS_APP_DEFINITION_ID, {
      url,
      closeOtherPanels: false,
    });
  }

  /**
   * setup custom state for Group Page
   * groupPage state behavior is described in manifest
   */
  async function configureGroupPage() {
    const pageRef = await sdk.tpa.getPageRefByTPAPageId(
      GROUPS_APP_DEFINITION_ID,
      {
        tpaPageId: 'group',
      },
    );

    await sdk.document.pages.setState(GROUPS_APP_DEFINITION_ID, {
      state: { groupPage: [pageRef] },
    });
  }

  /**
   * setup page titles
   */
  async function renamePages() {
    const [groupPageRef, groupListPageRef] = await Promise.all([
      sdk.tpa.getPageRefByTPAPageId(GROUPS_APP_DEFINITION_ID, {
        tpaPageId: 'group',
      }),
      sdk.tpa.getPageRefByTPAPageId(GROUPS_APP_DEFINITION_ID, {
        tpaPageId: 'groups',
      }),
    ]);

    await Promise.all([
      sdk.document.pages.rename(GROUPS_APP_DEFINITION_ID, {
        pageRef: groupPageRef,
        title: t('groups-web.initial.page-name.group-page'),
      }),
      sdk.document.pages.rename(GROUPS_APP_DEFINITION_ID, {
        pageRef: groupListPageRef,
        title: t('groups-web.initial.page-name.groups-page'),
      }),
    ]);
  }
};
